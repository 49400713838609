@charset "UTF-8";
// ブラウザノーマライズ(現在はress.cssをカスタマイズして使用)
@import "reset";
// 共通項目の設定をインポート
@import "utility";

a:hover img {
  opacity: .7;
  transition: .3s;
}


//////////////////////////////////////// 
// ヘッダーパターン
////////////////////////////////////////
.header_pattern_01 {
  border-top: 1px solid #c0c3c9;
  border-bottom: 1px solid #c0c3c9;
  line-height: 1.8;
  font-size: 25px;
  text-align: center;
  padding: 5px;
  @include bpm(mobile) {
    font-size: 2.7rem;
    font-size: 4vw;
  }
}

.header_pattern_02 {
  background: $main_color;
  color: #fff;
  width: 100%;
  line-height: 1.75;
  padding: 5px 15px;
  margin-bottom: 30px;
  font-size: 21px;
  @include bpm(mobile) {
    font-size: 2.6rem;
    font-size: 4vw;
    margin-bottom: 20px;
  }
}

.header_pattern_03 {
  color: $text_blue;
  padding-left: 25px;
  background: url(../images/common/hp03_bg.png) no-repeat top left;
  background-size: 16px 22px;
  line-height: 1.55;
  font-size: 20px;
  vertical-align: bottom;
  margin-bottom: 20px;
  @include bpm(mobile) {
    font-size: 2.7rem;
    font-size: 4.2vw;
    background-size: 10px 13px;
    padding-left: 15px;
    margin-bottom: 10px;
  }
}

// アーカイブページ　カテゴリー見出し
.header_pattern_04 {
  width: 100%;
  background: url(../images/common/hp04_bg.jpg) no-repeat;
  background-size: cover;
  font-size: 37px;
  line-height: 1.75;
  padding: 5px 0 5px 25px;
  border-left: 5px solid $text_blue;
  margin-bottom: 25px;
  color: #4d4d4d;
  @include bpm(mobile) {
    background: url(../images/common/hp04_bg_sp.jpg) no-repeat;
    background-size: cover;
    font-size: 3.5rem;
    font-size: 5.7vw;
    text-align: center;
    border-left: none;
    border-bottom: 2px solid $text_blue;
  }
}

// 基本は04と一緒だけど、h2用ではなくdiv用
.header_pattern_05 {
  width: 100%;
  background: url(../images/common/hp04_bg.jpg) no-repeat;
  background-size: cover;
  border-left: 5px solid $text_blue;
  margin-bottom: 25px;
  padding-left: 20px;
  @include bpm(mobile) {
    background: url(../images/common/hp04_bg_sp.jpg) no-repeat;
    background-size: cover;
    border-left: none;
    border-bottom: 2px solid $text_blue;
    padding-left: 0;
    margin-bottom: 15px;
  }
}

// シングルページまとめのヘッダー
.header_pattern_06 {
  width: 100%;
  background: $main_color;
  font-size: 23px;
  color: #fff;
  line-height: 1.75;
  padding: 2px;
  text-align: center;
  @include bpm(mobile) {
    font-size: 2.8rem;
    padding-top: 25px;
  }
}

.header_pattern_07 {
  font-family: $yu;
  font-size: 24px;
  color: $text_blue;
  line-height: 1.75;
  padding: 7px 5px;
  border-top: 1px solid $text_blue;
  border-bottom: 1px solid $text_blue;
  margin-bottom: 20px;
  @include bpm(mobile) {
    text-align: center;
    padding: 5px;
    font-size: 20px;
    font-size: 5vw;
  }
}

///////////////////////////////////////////


// ヘッダー上部
.header_top {
  &.inner {
    margin: 0 auto;
    margin-bottom: 10px;
    @include bpm(mobile) {
      width: 100%;
      padding: 0;
    }
  }
  @include bpm(mobile) {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .logo {
    float: left;
    width: 250px;
    margin-right: 20px;
    padding-top: 15px;
    img {
      width: 100%;
    }
    @include bpm(mobile) {
      float: none;
      width: 54.2%;
      margin: 0 auto;
    }
  }
  .header_info {
    float: left;
    width: 575px;
    @include bpm(mobile) {
      float: none;
      width: 100%;
    }
  }
  .header_banner {
    float: right;
    width: 135px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.header_info {
  .info_link {
    padding-top: 5px;
    text-align: right;
    margin-bottom: 3px;
    line-height: 1.7;
    li {
      display: inline-block;
      font-size: 12px;
      font-family: $hiragino;
      line-height: 1.5;
      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
        vertical-align: text-top;
      }
      &:first-of-type {
        margin-right: 10px;
      }
      a {
        text-decoration: none;
        color: #505050;
      	display: inline-block;
      }
      &:hover {
      	-webkit-animation: zoom .3s;
      	animation: zoom .3s;
      }
      @-webkit-keyframes zoom {
      	50% {
      		-webkit-transform: scale(1.05);
      	}
      }
      @keyframes zoom {
      	50% {
      		transform: scale(1.05);
      	}
      }
    }
  }
  .h1_text {
    font-size: 12px;
    font-family: $hiragino;
    font-weight: normal;
    line-height: 1.75;
    margin-bottom: 10px;
    @include bpm(mobile) {
      width: 100%;
      background: #ddf9ff;
      margin: 0 auto;
      text-align: center;
      padding: 3px 0;
      font-size: 1.5rem;
      font-size: 1.7vw;
    }
  }
}

// グロナビ
.glonav {
  width: inherit;
  line-height: 1.7;
  li {
    display: inline-block;
    font-weight: bold;
    margin-right: 20px;
    font-size: 18px;
    &:last-of-type {
      margin-right: 0;
    }
    a{
      text-decoration: none;
      color: $text_c;
      position: relative;
	    display: inline-block;
	    transition: .3s;
      &::after {
        position: absolute;
      	bottom: -2px;
      	left: 50%;
      	content: '';
      	width: 0;
      	height: 2px;
      	background-color: $main_color;
      	transition: .3s;
      	-webkit-transform: translateX(-50%);
      	transform: translateX(-50%);
      }
      &.kurozumi::after {background: $text_blue;}
      &.keana::after {background: #40d5bb;}
      &.shimi::after {background: #c7c298;}
      &.skincare::after {background: #ffb19f;}
      &.nikibi::after {background: #b1b1dd;}
      &.cleansing::after {background: #fccb7a;}
      &:hover::after {
	      width: 100%;
      }
    }
  }
}

// ヒーローイメージ
.header_hero {
  width: 100%;
  text-align: center;
  background: url(../images/top/hero_bg.jpg) no-repeat;
  background-size: contain;
  height: 315px;
  @include bpm(mobile) {
    height: auto;
    background: none;
    background-size: auto;
  }
  img {
    width: 1000px;
    height: 315px;
    @include bpm(mobile) {
      width: 100%;
      height: auto;
    }
  }
}

// ヘッダーリード
.header_lead {
  width: 825px;
  margin: 0 auto 50px;
  @include bpm(mobile) {
    width: 100%;
    margin-bottom: 30px;
  }
  .header_pattern_01 {
    color: #444e61;
    margin-bottom: 20px;
  }
  .lead_text {
    color: $text_c;
    line-height: 2;
    font-size: 16px;
    @include bpm(mobile) {
      font-size: 2.1rem;
      font-size: 3.3vw;
    }
  }
}


.container {
  margin: 0 auto 50px;
  @include bpm(mobile) {
    margin-bottom: 20px;
  }
}
//////////////////////////////////////// 
// メインカラム
////////////////////////////////////////
.main_contents {
  width: 700px;
  float: left;
  @include bpm(mobile) {
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }
}

.top.image_full {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  @include bpm(mobile) {
    margin-bottom: 15px;
  }
}
.top_text {
  margin-bottom: 40px;
  @include bpm(mobile) {
    margin-bottom: 30px;
  }
}

.top_bottom_bnr {
  width: 100%;
  height: auto;
}

//////////////////////////////////////// 
// サイドバー
////////////////////////////////////////
.sidebar {
  width: 270px;
  float: right;
  @include bpm(mobile) {
    width: 100%;
    float: none;
  }
}

.side_profile {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.side_tag {
  border: 1px solid #ccc;
  padding: 20px 15px;
  margin-bottom: 35px;
  @include bpm(mobile) {
    padding: 10px 20px;
    margin-bottom: 25px;
  }
  .tag_header {
    line-height: 1.75;
    font-size: 19px;
    color: $text_blue;
    text-align: center;
    margin-bottom: 20px;
    @include bpm(mobile) {
      font-size: 2.6rem;
      font-size: 4vw;
      margin-bottom: 10px;
    }
  }
}
.side_tag_list {
  display: block;
  margin: 0 auto;
  li {
    display: inline-block;
    background: #dfffff;
    line-height: 2;
    padding: 3px 10px;
    margin-bottom: 10px;
    margin-right: 5px;
    font-size: 16px;
    @include bpm(mobile) {
      font-size: 2.3rem;
      font-size: 3.7vw;
      padding: 3px 7px;
    }
    a {
      text-decoration: none;
      color: $main_color;
      &:hover {
        color: #333;
        transition: .6s;
      }
    }
  }
}

.recent_entry {
  width: 100%;
  margin-bottom: 35px;
  .recent_header {
    background: #ffffc2;
    width: 100%;
    color: $text_c;
    line-height: 1.75;
    text-align: center;
    padding: 8px;
    font-size: 19px;
    margin-bottom: 20px;
    @include bpm(mobile) {
      font-size: 2.7rem;
      font-size: 4.4vw;
    }
  }
}
.side_entry {
  padding: 8px 0;
  border-bottom: 1px dotted #999;
  &:first-of-type {
    padding-top: 0;
  }
  .side_thumb {
    width: 90px;
    height: 60px;
    float: left;
    @include bpm(mobile) {
      width: 26.8%;
      height: auto;
    }
  }
  .side_entry_title {
    width: 170px;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.56;
    color: $text_blue;
    float: right;
    padding-top: 5px;
    @include bpm(mobile) {
      width: 67.7%;
      font-size: 2.4rem;
      font-size: 3.6vw;
    }
  }
  a {
    text-decoration: none;
  }
}

.side_bnr_image {
  width: 100%;
  height: auto;
  @include bpm(mobile) {
    margin-bottom: 30px;
  }
}

@include bpm(mobile) {
  .pagetop_sp {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 17%;
    }
  }
}


//////////////////////////////////////// 
// フッター
////////////////////////////////////////
.footer {
  width: 100%;
  background: #ddf9ff;
  padding-top: 30px;
  padding-bottom: 25px;
  @include bpm(mobile) {
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .inner {
    margin: 0 auto;
  }
  .footer_logo {
    width: 220px;
    height: 65px;
    float: left;
    margin-right: 50px;
    @include bpm(mobile) {
      width: 52.7%;
      height: auto;
      float: none;
      margin: 0 auto 10px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .footer_link {
    float: left;
    width: 730px;
    padding-top: 30px;
    @include bpm(mobile) {
      float: none;
      width: 100%;
      padding-top: 0;
    }
    ul {
      @include bpm(mobile) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 10px;
      }
    }
    li {
      display: inline-block;
      font-size: 14px;
      line-height: 1.75;
      font-family: $hiragino;
      margin-right: 25px;
      @include bpm(mobile) {
        font-size: 1.8rem;
        font-size: 3vw;
      }
      &:last-of-type {
        margin-right: 0;
      }
      a {
        color: #505050;
        text-decoration: none;
        display: inline-block;
      }
      &:hover {
      	-webkit-animation: zoom .3s;
      	animation: zoom .3s;
      }
      @-webkit-keyframes zoom {
      	50% {
      		-webkit-transform: scale(1.05);
      	}
      }
      @keyframes zoom {
      	50% {
      		transform: scale(1.05);
      	}
      }
    }
  }
  .copyright {
    display: block;
    width: 100%;
    float: left;
    text-align: right;
    @include bpm(mobile) {
      text-align: center;
    }
    small {
      font-size: 14px;
      line-height: 1.75;
      color: #505050;
      font-family: $hiragino;
      @include bpm(mobile) {
        font-size: 1.7rem;
        font-size: 2.6vw;
      }
    }
  }
}


//////////////////////////////////////// 
// ページトップに戻る
////////////////////////////////////////
.pagetop_pc {
  position: fixed;
  bottom: 100px;
  right: 60px;
}




//////////////////////////////////////// 
// SP nav
////////////////////////////////////////

// sp menu 
.hbg_block {
  display: block;
}

.hbg {
  @include bpm(mobile) {
    position: absolute;
    right: 7vw;
    top: 40px;
    width: 11.6%;
  }
}

.menu-trigger {
  position: absolute;
  top: 5%;
  right: 9%;
  z-index: 250;
  height: 60px;
}
.menu-trigger img {
  max-width: 45px;
  width: 100%;
/*  display: block;*/
  position: relative;
/*  margin-bottom: 9px;*/
  display: block;
}

#slideL {
  z-index:500;
  cursor: pointer;
  position: fixed;
  top: 120px;
  right: -400px;
  width: 47%;
  height: auto;
  padding: 0 0px 0 0;
  margin-bottom: 0;
  background: #fff;
}
#slideL .slide-inner {
  overflow: auto;
  overflow-x: hidden;
  background: #ddd;
}

.gNav__list {
  list-style: none;
}
#slideL li {
  width: 100%;
  height: auto;
  background: #f2f2f2;
  border-bottom: 1px solid #fff;
  &.bottom_item {
    border-bottom: none;
    background: #01dcef;
    a {
      color: #fff;
      padding: 20px;
      padding-left: 25px;
      padding-right: 0;
    }
  }
  a {
    display: block;
    font-size: 2.4rem;
    font-size: 3.6vw;
    text-decoration: none;
    color: $text_c;
    font-weight: bold;
    padding: 15px;
    padding-left: 25px;
    padding-right: 0;
  }
}
#slideL li img {
  display: block;
  width: 100%;
}

label {
  color: #fff;
  display: block;
}

input[type="checkbox"].on-off{
  display: none;
}

.sp_menu_cat ul {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  margin: 0;
  padding: 0;
  list-style: none;
}

#slideL .sp_menu_cat li {
  width: 100%;
  box-sizing: border-box;
}
#slideL .sp_menu_cat li:last-of-type {
  border-bottom: none;
}
#slideL .sp_menu_cat li a {
  text-decoration: none;
}


input[type="checkbox"].on-off + ul{
  height: 0;
  overflow: hidden;
}

input[type="checkbox"].on-off.scene:checked + ul{
  height: 240px;
}
input[type="checkbox"].on-off.type:checked + ul{
  height: 140px;
}
input[type="checkbox"].on-off.item:checked + ul{
  height: 140px;
}




////////////////////////////////////////
// アーカイブページ
////////////////////////////////////////
.entry_block {
  border-bottom: 1px dotted #999;
  padding-bottom: 25px;
  margin-bottom: 20px;
  @include bpm(mobile) {
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
}

.entry_block .archive_thumb {
    float: left;
    width: 240px;
    height: 160px;
    @include bpm(mobile) {
      width: 42.9%;
      height: auto;
    }
}
.entry_block .entry_info {
  float: right;
  width: 440px;
  @include bpm(mobile) {
    width: 52.5%;
  }
  // カテゴリー関連
  .cat_list {
    margin-bottom: 5px;
    @include bpm(mobile) {
      padding-top: 5px;
    }
    li {
      display: inline-block;
      margin-right: 5px;
      &:last-of-type {
        margin-right: 0;
      }
      .cat_badge {
        color: #fff;
        font-size: 15px;
        line-height: 1.65;
        font-weight: bold;
        // color: #fff;
        background: #333;
        padding: 2px 10px 1px;
        text-align: center;
        @include bpm(mobile) {
          font-size: 1.7rem;
          font-size: 2.2vw;
        }
        &.kurozumi {background: $text_blue;}
        &.keana {background: #40d5bb;}
        &.shimi {background: #c7c298;}
        &.skincare {background: #ffb19f;}
        &.nikibi {background: #b1b1dd;}
        &.cleansing {background: #fccb7a;}
      }
    }
  }
  // 記事タイトル
  .entry_title {
    font-size: 22px;
    line-height: 1.5;
    color: $text_blue;
    a {
      color: $text_blue;
      text-decoration: none;
    }
    @include bpm(mobile) {
      font-size: 2.2rem;
      font-size: 3.6vw;
      
    }
  }
  // 投稿日時
  .entry_date {
    font-size: 12px;
    line-height: 2.2;
    font-family: $hiragino;
    color: #999;
    @include bpm(mobile) {
      font-size: 1rem;
      font-size: 2.6vw;
    }
  }
  // 抜粋
  .archive_excerption {
    font-size: 13px;
    line-height: 1.846;
    @include bpm(mobile) {
      display: none;
    }
  }
}


//////////////////////////////////////// 
// シングルページ
////////////////////////////////////////
.single_entry_header {
  // カテゴリーリスト
  .cat_list {
    margin-bottom: 5px;
    @include bpm(mobile) {
      padding-top: 5px;
      text-align: center;
    }
    li {
      display: inline-block;
      margin-right: 5px;
      &:last-of-type {
        margin-right: 0;
      }
      .cat_badge {
        color: #fff;
        font-size: 15px;
        line-height: 1.55;
        font-weight: bold;
        // color: #fff;
        background: #333;
        padding: 2px 10px 1px;
        text-align: center;
        @include bpm(mobile) {
          font-size: 1.7rem;
          font-size: 2.2vw;
        }
        &.kurozumi {background: $text_blue;}
        &.keana {background: #40d5bb;}
        &.shimi {background: #c7c298;}
        &.skincare {background: #ffb19f;}
        &.nikibi {background: #b1b1dd;}
        &.cleansing {background: #fccb7a;}
      }
    }
  }
  // 記事タイトル
  .single_title {
    font-size: 30px;
    line-height: 1.55;
    color: #4d4d4d;
    @include bpm(mobile) {
      font-size: 3.3rem;
      font-size: 5vw;
      text-align: center;
    }
  }
  // 投稿日時・タグ
  .entry_info {
    font-size: 12px;
    font-family: $hiragino;
    line-height: 1.75;
    @include bpm(mobile) {
      font-size: 1.7rem;
      font-size: 2.6vw;
      text-align: center;
      margin-bottom: 5px;
    }
    .entry_date {
      display: inline-block;
      margin-right: 10px;
      color: #999;
    }
    .tag_block {
      display: inline-block;
    }
  }
}

.single_entry_header .entry_info .tag_block {
  span {
    display: inline-block;
  }
  li {
    display: inline-block;
    &.tag_title {
      color: #646464;
    }
    &.tags {
      margin-right: 3px;
    }
    a {
      color: $text_blue;
    }
  }
}

.single_entry {
  .fullwidth_img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
  .single_lead {
    width: 100%;
    padding: 20px;
    background: #e6f9fa;
    margin-bottom: 50px;
    @include bpm(mobile) {
      margin-bottom: 25px;
    }
  }
  .split {
    width: 100%;
    margin-bottom: 50px;
    @include bpm(mobile) {
      margin-bottom: 25px;
    }
    .header_pattern_03 {
      margin-bottom: 10px;
    }
    @include bpm(mobile) {
      .left,.right {
        float: none;
      }
      .left {
        width: 64.3%;
        display: block;
        margin: 0 auto 15px;
      }
      .right {
        width: 100%;
      }
    }
  }
  .large_block {
    margin-bottom: 50px;
    @include bpm(mobile) {
      margin-bottom: 25px;
    }
  }
  .small_block {
    margin-bottom: 50px;
    @include bpm(mobile) {
      margin-bottom: 25px;
    }
  }
}

// まとめ
.matome {
  border: 2px solid $main_color;
  margin-bottom: 55px;
  padding: 0 20px 20px;
  @include bpm(mobile) {
    margin-top: 50px;
  }
  .header_pattern_06 {
    position: relative;
    margin-bottom: 20px;
    @include bpm(desktop) {
      padding-left: 70px;
      text-align: left;
    }
  }
  .matome_icon {
    width: 70px;
    height: auto;
    position: absolute;
    left: 0;
    top: -25px;
    @include bpm(mobile) {
      width: 20%;
      left: 29.5vw;
      top: -35px;
      
    }
  }
  p {
    color: $main_color;
  }
}

// ぱんくず
.breadcrumbs {
  margin-bottom: 20px;
  @include bpm(mobile) {
    display: none;
  }
  span[property="itemListElement"] {
    font-size: 11px;
    font-family: $hiragino;
    color: #333;
    @include bpm(mobile) {
      font-size: 3vw;
    }
    a {
      color: $main_color;
    }
  }
}

// サイトマップ
.sitemap_white {
  text-decoration: none;
  color: #fff;
}
.sitemap_black {
  text-decoration: none;
  color: #444e61;
}

// ページング
.pager {
  text-align: center;
  margin: 35px auto 50px;
  font-size: 22px;
  font-family: $yu;
  span.current {
    border: none;
    color: #4fb7e8;
    margin: 0 8px;
    font-weight: bold;
    @include bpm(mobile) {
      font-size: 4vw;
    }
  }
  a.page {
    border: none;
    margin: 0 8px;
    color: #000;
    font-weight: bold;
    @include bpm(mobile) {
      font-size: 4vw;
    }
  }
  a.nextpostslink {
    border: none;
    font-weight: bold;
    margin-left: 15px;
    font-size: 16px;
    color: #4fb7e8;
    background: #d1ffff;
    border-radius: 5px;
    line-height: 1.5;
    vertical-align: text-top;
    padding: 5px 10px;
    font-weight: bold;
    @include bpm(mobile) {
      font-size: 3vw;
      vertical-align: middle;
    }
  }
  a.previouspostslink {
    border: none;
    font-weight: bold;
    margin-right: 15px;
    font-size: 16px;
    color: #4fb7e8;
    background: #d1ffff;
    border-radius: 5px;
    line-height: 1.5;
    vertical-align: text-top;
    padding: 5px 10px;
    @include bpm(mobile) {
      font-size: 3vw;
      vertical-align: middle;
    }
  }
}

.bold_text,.bt {
  font-weight: bold;
}
.red_bold,.rb {
  font-weight: bold;
  color: red;
}



// シングルページ - 関連記事
.related_post_block {
  margin-bottom: 100px;
  ul {
    margin-bottom: 50px;
  }
  li {
    display: inline-block;
    width: 32.1%;
    margin-right: 1.25%;
    vertical-align: top;
    margin-bottom: 30px;
    &:nth-of-type(3n) {margin-right: 0;}
    @include bpm(mobile) {
      display: block;
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
      margin-bottom: 10px;
    }
    .related_title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
      @include bpm(mobile) {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  .more_btn_block {
    width: 100%;
    text-align: right;
    img {
      width: 350px;
    }
    @include bpm(mobile) {
      display: block;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
}