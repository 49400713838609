@charset "UTF-8";
/* # =================================================================
# Global selectors
# ================================================================= */
html {
  box-sizing: border-box;
  overflow-y: auto;
  /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

/* Remove margin, padding of all elements and set background-no-repeat as default */
* {
  background-repeat: no-repeat;
  /* Set `background-repeat: no-repeat` to all elements */
  padding: 0;
  /* Reset `padding` and `margin` of all elements */
  margin: 0;
}

/* # =================================================================
# General elements
# ================================================================= */
/* Add the correct display in iOS 4-7.*/
audio:not([controls]) {
  display: none;
  height: 0;
}

hr {
  overflow: visible;
  /* Show the overflow in Edge and IE */
}

/*
* Correct `block` display not defined for any HTML5 element in IE 8/9
* Correct `block` display not defined for `details` or `summary` in IE 10/11
* and Firefox
* Correct `block` display not defined for `main` in IE 11
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

summary {
  display: list-item;
  /* Add the correct display in all browsers */
}

small {
  font-size: 80%;
  /* Set font-size to 80% in `small` elements */
}

[hidden],
template {
  display: none;
  /* Add the correct display in IE */
}

abbr[title] {
  border-bottom: 1px dotted;
  /* Add a bordered underline effect in all browsers */
  text-decoration: none;
  /* Remove text decoration in Firefox 40+ */
}

a {
  background-color: transparent;
  /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects;
  /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
}

a:active,
a:hover {
  outline-width: 0;
  /* Remove the outline when hovering in all browsers */
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* Specify the font family of code elements */
}

b,
strong {
  font-weight: bolder;
  /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */
}

dfn {
  font-style: italic;
  /* Address styling not present in Safari and Chrome */
}

/* Address styling not present in IE 8/9 */
mark {
  background-color: #ff0;
  color: #000;
}

/* https://gist.github.com/unruthless/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* # =================================================================
# Forms
# ================================================================= */
/* Apply cursor pointer to button elements */
button,
[type="button"],
[type="reset"],
[type="submit"]
[role="button"] {
  cursor: pointer;
}

/* Replace pointer cursor in disabled elements */
[disabled] {
  cursor: default;
}

[type="number"] {
  width: auto;
  /* Firefox 36+ */
}

[type="search"] {
  -webkit-appearance: textfield;
  /* Safari 8+ */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* Safari 8 */
}

textarea {
  overflow: auto;
  /* Internet Explorer 11+ */
  resize: vertical;
  /* Specify textarea resizability */
}

optgroup {
  font-weight: bold;
  /* Restore the font weight unset by the previous rule. */
}

button {
  overflow: visible;
  /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS */
}

button,
select {
  text-transform: none;
  /* Firefox 40+, Internet Explorer 11- */
}

/* Remove the default button styling in all browsers */
/* Style select like a standard input */
select {
  /*  -moz-appearance: none;  Firefox 36+ */
  /*  -webkit-appearance: none;  Chrome 41+ */
}

select::-ms-expand {
  display: none;
  /* Internet Explorer 11+ */
}

select::-ms-value {
  color: currentColor;
  /* Internet Explorer 11+ */
}

legend {
  border: 0;
  /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit;
  /* Correct the color inheritance from `fieldset` elements in IE */
  display: table;
  /* Correct the text wrapping in Edge and IE */
  max-width: 100%;
  /* Correct the text wrapping in Edge and IE */
  white-space: normal;
  /* Correct the text wrapping in Edge and IE */
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit;
  /* Change font properties to `inherit` in Chrome and Safari */
}

[type="search"] {
  -webkit-appearance: textfield;
  /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px;
  /* Correct the outline style in Safari */
}

/* # =================================================================
# Specify media element style
# ================================================================= */
img {
  border-style: none;
  /* Remove border when inside `a` element in IE 8/9/10 */
}

/* Add the correct vertical alignment in Chrome, Firefox, and Opera */
progress {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
  /* Internet Explorer 11- */
}

audio,
canvas,
progress,
video {
  display: inline-block;
  /* Internet Explorer 11+, Windows Phone 8.1+ */
}

/* # =================================================================
# Accessibility
# ================================================================= */
/* Hide content from screens but not screenreaders */
@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}

/* Specify the progress cursor of updating elements */
[aria-busy="true"] {
  cursor: progress;
}

/* Specify the pointer cursor of trigger elements */
[aria-controls] {
  cursor: pointer;
}

/* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
[aria-disabled] {
  cursor: default;
}

/* # =================================================================
# Selection
# ================================================================= */
/* Specify text selection background color and omit drop shadow */
::-moz-selection {
  background-color: #b3d4fc;
  /* Required when declaring ::selection */
  color: #fff;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.mb0 {
  margin-bottom: 0px;
}

.mt0 {
  margin-top: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mt5 {
  margin-top: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mt15 {
  margin-top: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mt25 {
  margin-top: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt30 {
  margin-top: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mt35 {
  margin-top: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt40 {
  margin-top: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mt45 {
  margin-top: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt50 {
  margin-top: 50px;
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz19 {
  font-size: 19px;
}

.fz20 {
  font-size: 20px;
}

.fz21 {
  font-size: 21px;
}

.fz22 {
  font-size: 22px;
}

.fz23 {
  font-size: 23px;
}

.fz24 {
  font-size: 24px;
}

.fz25 {
  font-size: 25px;
}

.fz26 {
  font-size: 26px;
}

.fz27 {
  font-size: 27px;
}

.fz28 {
  font-size: 28px;
}

.fz29 {
  font-size: 29px;
}

.fz30 {
  font-size: 30px;
}

html {
  font-size: 10px;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 40%;
  }
}

body {
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
}

.left {
  float: left;
}

.right {
  float: right;
}

.cl:after {
  display: block;
  content: '';
  clear: both;
}

.pc_block {
  display: block;
}

@media only screen and (max-width: 768px) {
  .pc_block {
    display: none;
  }
}

.pc_inline {
  display: inline;
}

@media only screen and (max-width: 768px) {
  .pc_inline {
    display: none;
  }
}

.sp_block {
  display: none;
}

@media only screen and (max-width: 768px) {
  .sp_block {
    display: block;
  }
}

.sp_inline {
  display: none;
}

@media only screen and (max-width: 768px) {
  .sp_inline {
    display: inline;
  }
}

.inner {
  width: 1000px;
}

@media only screen and (max-width: 768px) {
  .inner {
    width: 100%;
    padding: 0 6.4%;
  }
}

p {
  font-size: 16px;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
  color: #444e61;
  line-height: 2;
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 2.1rem;
    font-size: 3.3vw;
  }
}

.w100img {
  width: 100%;
  height: auto;
}

a:hover img {
  opacity: .7;
  transition: .3s;
}

.header_pattern_01 {
  border-top: 1px solid #c0c3c9;
  border-bottom: 1px solid #c0c3c9;
  line-height: 1.8;
  font-size: 25px;
  text-align: center;
  padding: 5px;
}

@media only screen and (max-width: 768px) {
  .header_pattern_01 {
    font-size: 2.7rem;
    font-size: 4vw;
  }
}

.header_pattern_02 {
  background: #00c3e6;
  color: #fff;
  width: 100%;
  line-height: 1.75;
  padding: 5px 15px;
  margin-bottom: 30px;
  font-size: 21px;
}

@media only screen and (max-width: 768px) {
  .header_pattern_02 {
    font-size: 2.6rem;
    font-size: 4vw;
    margin-bottom: 20px;
  }
}

.header_pattern_03 {
  color: #4fb7e8;
  padding-left: 25px;
  background: url(../images/common/hp03_bg.png) no-repeat top left;
  background-size: 16px 22px;
  line-height: 1.55;
  font-size: 20px;
  vertical-align: bottom;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .header_pattern_03 {
    font-size: 2.7rem;
    font-size: 4.2vw;
    background-size: 10px 13px;
    padding-left: 15px;
    margin-bottom: 10px;
  }
}

.header_pattern_04 {
  width: 100%;
  background: url(../images/common/hp04_bg.jpg) no-repeat;
  background-size: cover;
  font-size: 37px;
  line-height: 1.75;
  padding: 5px 0 5px 25px;
  border-left: 5px solid #4fb7e8;
  margin-bottom: 25px;
  color: #4d4d4d;
}

@media only screen and (max-width: 768px) {
  .header_pattern_04 {
    background: url(../images/common/hp04_bg_sp.jpg) no-repeat;
    background-size: cover;
    font-size: 3.5rem;
    font-size: 5.7vw;
    text-align: center;
    border-left: none;
    border-bottom: 2px solid #4fb7e8;
  }
}

.header_pattern_05 {
  width: 100%;
  background: url(../images/common/hp04_bg.jpg) no-repeat;
  background-size: cover;
  border-left: 5px solid #4fb7e8;
  margin-bottom: 25px;
  padding-left: 20px;
}

@media only screen and (max-width: 768px) {
  .header_pattern_05 {
    background: url(../images/common/hp04_bg_sp.jpg) no-repeat;
    background-size: cover;
    border-left: none;
    border-bottom: 2px solid #4fb7e8;
    padding-left: 0;
    margin-bottom: 15px;
  }
}

.header_pattern_06 {
  width: 100%;
  background: #00c3e6;
  font-size: 23px;
  color: #fff;
  line-height: 1.75;
  padding: 2px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .header_pattern_06 {
    font-size: 2.8rem;
    padding-top: 25px;
  }
}

.header_pattern_07 {
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
  font-size: 24px;
  color: #4fb7e8;
  line-height: 1.75;
  padding: 7px 5px;
  border-top: 1px solid #4fb7e8;
  border-bottom: 1px solid #4fb7e8;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .header_pattern_07 {
    text-align: center;
    padding: 5px;
    font-size: 20px;
    font-size: 5vw;
  }
}

.header_top.inner {
  margin: 0 auto;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .header_top.inner {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .header_top {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.header_top .logo {
  float: left;
  width: 250px;
  margin-right: 20px;
  padding-top: 15px;
}

.header_top .logo img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .header_top .logo {
    float: none;
    width: 54.2%;
    margin: 0 auto;
  }
}

.header_top .header_info {
  float: left;
  width: 575px;
}

@media only screen and (max-width: 768px) {
  .header_top .header_info {
    float: none;
    width: 100%;
  }
}

.header_top .header_banner {
  float: right;
  width: 135px;
}

.header_top .header_banner img {
  width: 100%;
  height: auto;
}

.header_info .info_link {
  padding-top: 5px;
  text-align: right;
  margin-bottom: 3px;
  line-height: 1.7;
}

.header_info .info_link li {
  display: inline-block;
  font-size: 12px;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
  line-height: 1.5;
}

.header_info .info_link li img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  vertical-align: text-top;
}

.header_info .info_link li:first-of-type {
  margin-right: 10px;
}

.header_info .info_link li a {
  text-decoration: none;
  color: #505050;
  display: inline-block;
}

.header_info .info_link li:hover {
  -webkit-animation: zoom .3s;
  animation: zoom .3s;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.05);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.05);
  }
}

.header_info .h1_text {
  font-size: 12px;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
  font-weight: normal;
  line-height: 1.75;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .header_info .h1_text {
    width: 100%;
    background: #ddf9ff;
    margin: 0 auto;
    text-align: center;
    padding: 3px 0;
    font-size: 1.5rem;
    font-size: 1.7vw;
  }
}

.glonav {
  width: inherit;
  line-height: 1.7;
}

.glonav li {
  display: inline-block;
  font-weight: bold;
  margin-right: 20px;
  font-size: 18px;
}

.glonav li:last-of-type {
  margin-right: 0;
}

.glonav li a {
  text-decoration: none;
  color: #444e61;
  position: relative;
  display: inline-block;
  transition: .3s;
}

.glonav li a::after {
  position: absolute;
  bottom: -2px;
  left: 50%;
  content: '';
  width: 0;
  height: 2px;
  background-color: #00c3e6;
  transition: .3s;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.glonav li a.kurozumi::after {
  background: #4fb7e8;
}

.glonav li a.keana::after {
  background: #40d5bb;
}

.glonav li a.shimi::after {
  background: #c7c298;
}

.glonav li a.skincare::after {
  background: #ffb19f;
}

.glonav li a.nikibi::after {
  background: #b1b1dd;
}

.glonav li a.cleansing::after {
  background: #fccb7a;
}

.glonav li a:hover::after {
  width: 100%;
}

.header_hero {
  width: 100%;
  text-align: center;
  background: url(../images/top/hero_bg.jpg) no-repeat;
  background-size: contain;
  height: 315px;
}

@media only screen and (max-width: 768px) {
  .header_hero {
    height: auto;
    background: none;
    background-size: auto;
  }
}

.header_hero img {
  width: 1000px;
  height: 315px;
}

@media only screen and (max-width: 768px) {
  .header_hero img {
    width: 100%;
    height: auto;
  }
}

.header_lead {
  width: 825px;
  margin: 0 auto 50px;
}

@media only screen and (max-width: 768px) {
  .header_lead {
    width: 100%;
    margin-bottom: 30px;
  }
}

.header_lead .header_pattern_01 {
  color: #444e61;
  margin-bottom: 20px;
}

.header_lead .lead_text {
  color: #444e61;
  line-height: 2;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .header_lead .lead_text {
    font-size: 2.1rem;
    font-size: 3.3vw;
  }
}

.container {
  margin: 0 auto 50px;
}

@media only screen and (max-width: 768px) {
  .container {
    margin-bottom: 20px;
  }
}

.main_contents {
  width: 700px;
  float: left;
}

@media only screen and (max-width: 768px) {
  .main_contents {
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }
}

.top.image_full {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .top.image_full {
    margin-bottom: 15px;
  }
}

.top_text {
  margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .top_text {
    margin-bottom: 30px;
  }
}

.top_bottom_bnr {
  width: 100%;
  height: auto;
}

.sidebar {
  width: 270px;
  float: right;
}

@media only screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    float: none;
  }
}

.side_profile {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.side_tag {
  border: 1px solid #ccc;
  padding: 20px 15px;
  margin-bottom: 35px;
}

@media only screen and (max-width: 768px) {
  .side_tag {
    padding: 10px 20px;
    margin-bottom: 25px;
  }
}

.side_tag .tag_header {
  line-height: 1.75;
  font-size: 19px;
  color: #4fb7e8;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .side_tag .tag_header {
    font-size: 2.6rem;
    font-size: 4vw;
    margin-bottom: 10px;
  }
}

.side_tag_list {
  display: block;
  margin: 0 auto;
}

.side_tag_list li {
  display: inline-block;
  background: #dfffff;
  line-height: 2;
  padding: 3px 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .side_tag_list li {
    font-size: 2.3rem;
    font-size: 3.7vw;
    padding: 3px 7px;
  }
}

.side_tag_list li a {
  text-decoration: none;
  color: #00c3e6;
}

.side_tag_list li a:hover {
  color: #333;
  transition: .6s;
}

.recent_entry {
  width: 100%;
  margin-bottom: 35px;
}

.recent_entry .recent_header {
  background: #ffffc2;
  width: 100%;
  color: #444e61;
  line-height: 1.75;
  text-align: center;
  padding: 8px;
  font-size: 19px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .recent_entry .recent_header {
    font-size: 2.7rem;
    font-size: 4.4vw;
  }
}

.side_entry {
  padding: 8px 0;
  border-bottom: 1px dotted #999;
}

.side_entry:first-of-type {
  padding-top: 0;
}

.side_entry .side_thumb {
  width: 90px;
  height: 60px;
  float: left;
}

@media only screen and (max-width: 768px) {
  .side_entry .side_thumb {
    width: 26.8%;
    height: auto;
  }
}

.side_entry .side_entry_title {
  width: 170px;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.56;
  color: #4fb7e8;
  float: right;
  padding-top: 5px;
}

@media only screen and (max-width: 768px) {
  .side_entry .side_entry_title {
    width: 67.7%;
    font-size: 2.4rem;
    font-size: 3.6vw;
  }
}

.side_entry a {
  text-decoration: none;
}

.side_bnr_image {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .side_bnr_image {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .pagetop_sp {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .pagetop_sp img {
    width: 17%;
  }
}

.footer {
  width: 100%;
  background: #ddf9ff;
  padding-top: 30px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding-top: 15px;
    padding-bottom: 10px;
  }
}

.footer .inner {
  margin: 0 auto;
}

.footer .footer_logo {
  width: 220px;
  height: 65px;
  float: left;
  margin-right: 50px;
}

@media only screen and (max-width: 768px) {
  .footer .footer_logo {
    width: 52.7%;
    height: auto;
    float: none;
    margin: 0 auto 10px;
  }
}

.footer .footer_logo img {
  width: 100%;
  height: auto;
}

.footer .footer_link {
  float: left;
  width: 730px;
  padding-top: 30px;
}

@media only screen and (max-width: 768px) {
  .footer .footer_link {
    float: none;
    width: 100%;
    padding-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer .footer_link ul {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.footer .footer_link li {
  display: inline-block;
  font-size: 14px;
  line-height: 1.75;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
  margin-right: 25px;
}

@media only screen and (max-width: 768px) {
  .footer .footer_link li {
    font-size: 1.8rem;
    font-size: 3vw;
  }
}

.footer .footer_link li:last-of-type {
  margin-right: 0;
}

.footer .footer_link li a {
  color: #505050;
  text-decoration: none;
  display: inline-block;
}

.footer .footer_link li:hover {
  -webkit-animation: zoom .3s;
  animation: zoom .3s;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.05);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.05);
  }
}

.footer .copyright {
  display: block;
  width: 100%;
  float: left;
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .footer .copyright {
    text-align: center;
  }
}

.footer .copyright small {
  font-size: 14px;
  line-height: 1.75;
  color: #505050;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
}

@media only screen and (max-width: 768px) {
  .footer .copyright small {
    font-size: 1.7rem;
    font-size: 2.6vw;
  }
}

.pagetop_pc {
  position: fixed;
  bottom: 100px;
  right: 60px;
}

.hbg_block {
  display: block;
}

@media only screen and (max-width: 768px) {
  .hbg {
    position: absolute;
    right: 7vw;
    top: 40px;
    width: 11.6%;
  }
}

.menu-trigger {
  position: absolute;
  top: 5%;
  right: 9%;
  z-index: 250;
  height: 60px;
}

.menu-trigger img {
  max-width: 45px;
  width: 100%;
  /*  display: block;*/
  position: relative;
  /*  margin-bottom: 9px;*/
  display: block;
}

#slideL {
  z-index: 500;
  cursor: pointer;
  position: fixed;
  top: 120px;
  right: -400px;
  width: 47%;
  height: auto;
  padding: 0 0px 0 0;
  margin-bottom: 0;
  background: #fff;
}

#slideL .slide-inner {
  overflow: auto;
  overflow-x: hidden;
  background: #ddd;
}

.gNav__list {
  list-style: none;
}

#slideL li {
  width: 100%;
  height: auto;
  background: #f2f2f2;
  border-bottom: 1px solid #fff;
}

#slideL li.bottom_item {
  border-bottom: none;
  background: #01dcef;
}

#slideL li.bottom_item a {
  color: #fff;
  padding: 20px;
  padding-left: 25px;
  padding-right: 0;
}

#slideL li a {
  display: block;
  font-size: 2.4rem;
  font-size: 3.6vw;
  text-decoration: none;
  color: #444e61;
  font-weight: bold;
  padding: 15px;
  padding-left: 25px;
  padding-right: 0;
}

#slideL li img {
  display: block;
  width: 100%;
}

label {
  color: #fff;
  display: block;
}

input[type="checkbox"].on-off {
  display: none;
}

.sp_menu_cat ul {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  margin: 0;
  padding: 0;
  list-style: none;
}

#slideL .sp_menu_cat li {
  width: 100%;
  box-sizing: border-box;
}

#slideL .sp_menu_cat li:last-of-type {
  border-bottom: none;
}

#slideL .sp_menu_cat li a {
  text-decoration: none;
}

input[type="checkbox"].on-off + ul {
  height: 0;
  overflow: hidden;
}

input[type="checkbox"].on-off.scene:checked + ul {
  height: 240px;
}

input[type="checkbox"].on-off.type:checked + ul {
  height: 140px;
}

input[type="checkbox"].on-off.item:checked + ul {
  height: 140px;
}

.entry_block {
  border-bottom: 1px dotted #999;
  padding-bottom: 25px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .entry_block {
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
}

.entry_block .archive_thumb {
  float: left;
  width: 240px;
  height: 160px;
}

@media only screen and (max-width: 768px) {
  .entry_block .archive_thumb {
    width: 42.9%;
    height: auto;
  }
}

.entry_block .entry_info {
  float: right;
  width: 440px;
}

@media only screen and (max-width: 768px) {
  .entry_block .entry_info {
    width: 52.5%;
  }
}

.entry_block .entry_info .cat_list {
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  .entry_block .entry_info .cat_list {
    padding-top: 5px;
  }
}

.entry_block .entry_info .cat_list li {
  display: inline-block;
  margin-right: 5px;
}

.entry_block .entry_info .cat_list li:last-of-type {
  margin-right: 0;
}

.entry_block .entry_info .cat_list li .cat_badge {
  color: #fff;
  font-size: 15px;
  line-height: 1.65;
  font-weight: bold;
  background: #333;
  padding: 2px 10px 1px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .entry_block .entry_info .cat_list li .cat_badge {
    font-size: 1.7rem;
    font-size: 2.2vw;
  }
}

.entry_block .entry_info .cat_list li .cat_badge.kurozumi {
  background: #4fb7e8;
}

.entry_block .entry_info .cat_list li .cat_badge.keana {
  background: #40d5bb;
}

.entry_block .entry_info .cat_list li .cat_badge.shimi {
  background: #c7c298;
}

.entry_block .entry_info .cat_list li .cat_badge.skincare {
  background: #ffb19f;
}

.entry_block .entry_info .cat_list li .cat_badge.nikibi {
  background: #b1b1dd;
}

.entry_block .entry_info .cat_list li .cat_badge.cleansing {
  background: #fccb7a;
}

.entry_block .entry_info .entry_title {
  font-size: 22px;
  line-height: 1.5;
  color: #4fb7e8;
}

.entry_block .entry_info .entry_title a {
  color: #4fb7e8;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .entry_block .entry_info .entry_title {
    font-size: 2.2rem;
    font-size: 3.6vw;
  }
}

.entry_block .entry_info .entry_date {
  font-size: 12px;
  line-height: 2.2;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
  color: #999;
}

@media only screen and (max-width: 768px) {
  .entry_block .entry_info .entry_date {
    font-size: 1rem;
    font-size: 2.6vw;
  }
}

.entry_block .entry_info .archive_excerption {
  font-size: 13px;
  line-height: 1.846;
}

@media only screen and (max-width: 768px) {
  .entry_block .entry_info .archive_excerption {
    display: none;
  }
}

.single_entry_header .cat_list {
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  .single_entry_header .cat_list {
    padding-top: 5px;
    text-align: center;
  }
}

.single_entry_header .cat_list li {
  display: inline-block;
  margin-right: 5px;
}

.single_entry_header .cat_list li:last-of-type {
  margin-right: 0;
}

.single_entry_header .cat_list li .cat_badge {
  color: #fff;
  font-size: 15px;
  line-height: 1.55;
  font-weight: bold;
  background: #333;
  padding: 2px 10px 1px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .single_entry_header .cat_list li .cat_badge {
    font-size: 1.7rem;
    font-size: 2.2vw;
  }
}

.single_entry_header .cat_list li .cat_badge.kurozumi {
  background: #4fb7e8;
}

.single_entry_header .cat_list li .cat_badge.keana {
  background: #40d5bb;
}

.single_entry_header .cat_list li .cat_badge.shimi {
  background: #c7c298;
}

.single_entry_header .cat_list li .cat_badge.skincare {
  background: #ffb19f;
}

.single_entry_header .cat_list li .cat_badge.nikibi {
  background: #b1b1dd;
}

.single_entry_header .cat_list li .cat_badge.cleansing {
  background: #fccb7a;
}

.single_entry_header .single_title {
  font-size: 30px;
  line-height: 1.55;
  color: #4d4d4d;
}

@media only screen and (max-width: 768px) {
  .single_entry_header .single_title {
    font-size: 3.3rem;
    font-size: 5vw;
    text-align: center;
  }
}

.single_entry_header .entry_info {
  font-size: 12px;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
  line-height: 1.75;
}

@media only screen and (max-width: 768px) {
  .single_entry_header .entry_info {
    font-size: 1.7rem;
    font-size: 2.6vw;
    text-align: center;
    margin-bottom: 5px;
  }
}

.single_entry_header .entry_info .entry_date {
  display: inline-block;
  margin-right: 10px;
  color: #999;
}

.single_entry_header .entry_info .tag_block {
  display: inline-block;
}

.single_entry_header .entry_info .tag_block span {
  display: inline-block;
}

.single_entry_header .entry_info .tag_block li {
  display: inline-block;
}

.single_entry_header .entry_info .tag_block li.tag_title {
  color: #646464;
}

.single_entry_header .entry_info .tag_block li.tags {
  margin-right: 3px;
}

.single_entry_header .entry_info .tag_block li a {
  color: #4fb7e8;
}

.single_entry .fullwidth_img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.single_entry .single_lead {
  width: 100%;
  padding: 20px;
  background: #e6f9fa;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .single_entry .single_lead {
    margin-bottom: 25px;
  }
}

.single_entry .split {
  width: 100%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .single_entry .split {
    margin-bottom: 25px;
  }
}

.single_entry .split .header_pattern_03 {
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .single_entry .split .left, .single_entry .split .right {
    float: none;
  }
  .single_entry .split .left {
    width: 64.3%;
    display: block;
    margin: 0 auto 15px;
  }
  .single_entry .split .right {
    width: 100%;
  }
}

.single_entry .large_block {
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .single_entry .large_block {
    margin-bottom: 25px;
  }
}

.single_entry .small_block {
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .single_entry .small_block {
    margin-bottom: 25px;
  }
}

.matome {
  border: 2px solid #00c3e6;
  margin-bottom: 55px;
  padding: 0 20px 20px;
}

@media only screen and (max-width: 768px) {
  .matome {
    margin-top: 50px;
  }
}

.matome .header_pattern_06 {
  position: relative;
  margin-bottom: 20px;
}

@media only screen and (min-width: 769px) {
  .matome .header_pattern_06 {
    padding-left: 70px;
    text-align: left;
  }
}

.matome .matome_icon {
  width: 70px;
  height: auto;
  position: absolute;
  left: 0;
  top: -25px;
}

@media only screen and (max-width: 768px) {
  .matome .matome_icon {
    width: 20%;
    left: 29.5vw;
    top: -35px;
  }
}

.matome p {
  color: #00c3e6;
}

.breadcrumbs {
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .breadcrumbs {
    display: none;
  }
}

.breadcrumbs span[property="itemListElement"] {
  font-size: 11px;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
  color: #333;
}

@media only screen and (max-width: 768px) {
  .breadcrumbs span[property="itemListElement"] {
    font-size: 3vw;
  }
}

.breadcrumbs span[property="itemListElement"] a {
  color: #00c3e6;
}

.sitemap_white {
  text-decoration: none;
  color: #fff;
}

.sitemap_black {
  text-decoration: none;
  color: #444e61;
}

.pager {
  text-align: center;
  margin: 35px auto 50px;
  font-size: 22px;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
}

.pager span.current {
  border: none;
  color: #4fb7e8;
  margin: 0 8px;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .pager span.current {
    font-size: 4vw;
  }
}

.pager a.page {
  border: none;
  margin: 0 8px;
  color: #000;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .pager a.page {
    font-size: 4vw;
  }
}

.pager a.nextpostslink {
  border: none;
  font-weight: bold;
  margin-left: 15px;
  font-size: 16px;
  color: #4fb7e8;
  background: #d1ffff;
  border-radius: 5px;
  line-height: 1.5;
  vertical-align: text-top;
  padding: 5px 10px;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .pager a.nextpostslink {
    font-size: 3vw;
    vertical-align: middle;
  }
}

.pager a.previouspostslink {
  border: none;
  font-weight: bold;
  margin-right: 15px;
  font-size: 16px;
  color: #4fb7e8;
  background: #d1ffff;
  border-radius: 5px;
  line-height: 1.5;
  vertical-align: text-top;
  padding: 5px 10px;
}

@media only screen and (max-width: 768px) {
  .pager a.previouspostslink {
    font-size: 3vw;
    vertical-align: middle;
  }
}

.bold_text, .bt {
  font-weight: bold;
}

.red_bold, .rb {
  font-weight: bold;
  color: red;
}

.related_post_block {
  margin-bottom: 100px;
}

.related_post_block ul {
  margin-bottom: 50px;
}

.related_post_block li {
  display: inline-block;
  width: 32.1%;
  margin-right: 1.25%;
  vertical-align: top;
  margin-bottom: 30px;
}

.related_post_block li:nth-of-type(3n) {
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .related_post_block li {
    display: block;
    width: 100%;
  }
}

.related_post_block li img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  margin-bottom: 10px;
}

.related_post_block li .related_title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
}

@media only screen and (max-width: 768px) {
  .related_post_block li .related_title {
    text-align: center;
    margin-bottom: 20px;
  }
}

.related_post_block li a {
  text-decoration: none;
  color: inherit;
}

.related_post_block .more_btn_block {
  width: 100%;
  text-align: right;
}

.related_post_block .more_btn_block img {
  width: 350px;
}

@media only screen and (max-width: 768px) {
  .related_post_block .more_btn_block {
    display: block;
    margin: 0 auto;
  }
  .related_post_block .more_btn_block img {
    width: 100%;
  }
}
