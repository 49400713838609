@charset "UTF-8";

//////////////////////////////////////// 
// フォントパターン
////////////////////////////////////////
$yu: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
$meiryo: Meiryo, "メイリオ", "ＭＳ ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3";
$hiragino: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "ＭＳ ゴシック", "Osaka‐等幅";
$yu_min: "游明朝体", "Yu Mincho", YuMincho, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;


//////////////////////////////////////// 
// カラーパターン
////////////////////////////////////////
$main_color: #00c3e6;
$text_blue: #4fb7e8;
$text_c: #444e61;



@mixin bpm($point)
{
  @if $point == desktop{
    @media only screen and (min-width:769px)
    {
      @content;
    }
  }
  @else if $point == mobile{
    @media only screen and (max-width:768px)
    {
      @content;
    }
  }
}

@mixin flexbox
{
  display:-webkit-box; // old
  display:-moz-box; // old
  display:-ms-flexbox; // ie
  display:-webkit-flex; // new
  display:flex; // new
}

@for $i from 0 through 10 {
  .mb#{$i * 5} {
    margin-bottom:#{$i * 5}px;
  }
  .mt#{$i * 5} {
    margin-top:#{$i * 5}px;
  }
}
@for $i from 10 through 30 {
  .fz#{$i} {
    font-size: #{$i}px;
  }
}

// @for $i from 1 through 100 {
//   .w#{$i} {
//     width: #{$i}#{%};
//   }
// }


html {
  font-size: 10px;
  @include bpm(mobile) {
    font-size: 40%;
  }
}
body {
  font-family: $yu;
}


.left { float: left; }
.right { float: right; }

.cl:after {
  display: block;
  content: '';
  clear: both;
}

.pc_block {
  display: block;
  @include bpm(mobile) {
    display: none;
  }
}
.pc_inline {
  display: inline;
  @include bpm(mobile) {
    display: none;
  }
}

.sp_block {
  display: none;
  @include bpm(mobile) {
    display: block;
  }
}
.sp_inline {
  display: none;
  @include bpm(mobile) {
    display: inline;
  }
}

.inner {
  width: 1000px; // default project width
  @include bpm(mobile) {
    width: 100%;
    padding: 0 6.4%;
  }
}

p {
  font-size: 16px;
  font-family: $yu;
  color: $text_c;
  line-height: 2;
  @include bpm(mobile) {
    font-size: 2.1rem;
    font-size: 3.3vw;
  }
}

.w100img {
  width: 100%;
  height: auto;
}